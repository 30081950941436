import React from "react";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  } from "chart.js";

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

const Chart = ({result, ...props}) => <Bar
    options={{
        scales: {
            x: {
                stacked: true
            },
            y: {
                stacked: true
            }
        }
      }}
    data={{
        labels: ['Amount in Rs'],
        datasets: [{
            label: 'Invested Amount',
            data: [result.amountInvested],
            backgroundColor: '#F2AF29',
          },
          {
            label: 'Profit Earned',
            data: [result.profitEarned],
            backgroundColor: '#45CB85',
          }
        ]
      }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />

export default Chart