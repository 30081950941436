import React from "react";
import Helmet from "react-helmet";
import Layout from "../layout";
import SEO from "../components/SEO";
import ViewCounter from "../components/ViewCounter";
import { calculateProjectedData, calculateSIP } from "../utils/calculateSip";
import Chart from "../components/Chart";

const initialErrorValue = {
  investmentAmount: false,
  investmentPeriod: false,
  annualRate: false,
};

const SipCalculator = () => {
  const [investmentAmount, setInvestmentAmount] = React.useState("");
  const [investmentType, setInvestmentType] = React.useState("monthly");
  const [investmentPeriod, setInvestmentPeriod] = React.useState("");
  const [annualRate, setAnnualRate] = React.useState("");
  const [adjustInflation, setAdjustInflation] = React.useState("no");
  const [result, setResult] = React.useState({
    amountInvested: 0,
    expectedAmount: 0,
    profitEarned: 0,
  });

  const [errors, setErrors] = React.useState({ ...initialErrorValue });

  const validateInput = () => {
    if (
      investmentAmount === "" ||
      investmentPeriod === "" ||
      annualRate === ""
    ) {
      return false;
    }
    return true;
  };

  const setValidations = () => {
    const validations = {
      investmentAmount: false,
      investmentPeriod: false,
      annualRate: false,
    };
    if (investmentAmount === "") {
      validations.investmentAmount = true;
    }
    if (investmentPeriod === "") {
      validations.investmentPeriod = true;
    }
    if (annualRate === "") {
      validations.annualRate = true;
    }

    setErrors(validations);
  };

  const createTable = () => {
    let variableYear = Number(investmentPeriod);
    let tableDynamic = ``;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 4; i++) {
      const projectedResult = calculateProjectedData(
        investmentAmount,
        investmentType,
        variableYear,
        annualRate,
        adjustInflation
      );
      tableDynamic += `
      <tr>
        <td>${variableYear} years </td>
        <td>Rs ${investmentAmount}</td>
        <td>Rs ${projectedResult.expectedAmount || 0}</td>
       </tr> 
      `;
      variableYear += 4;
    }
    document.getElementById(
      "tab_cap"
    ).innerText = `Projected SIP returns. [ @${annualRate} ]`;
    document.getElementById("projectTable").innerHTML = tableDynamic;
    document.getElementById("result-box").style.display = "block";
  };

  const handleSubmit = () => {
    if (validateInput()) {
      setErrors({ ...initialErrorValue });
      setResult(
        calculateSIP(
          investmentAmount,
          investmentType,
          investmentPeriod,
          annualRate,
          adjustInflation
        )
      );
      createTable();
    } else {
      setValidations();
    }
  };

  return (
    <Layout>
      <SEO />
      <Helmet title={`SIP Calculator : Start your investment!`} />
      <span style={{ display: "none" }}>
        <ViewCounter id="sip-calculator" />
      </span>
      <div className="sip-container">
        <h1 style={{ textAlign: "center" }}>SIP CALCULATOR</h1>
        <div className="">
          <p>{`Give it a try today and see how you can benefit from the power of systematic investing.`}</p>
          <p>{`To get started, enter the desired field and click the Calculate button.`}</p>
        </div>
        <div className="res-box">
          <div className="row">
            <div className="col">
              <label>
                Investment Amount(Rs.): <em className="sip-alert">*</em>
              </label>

              {errors.investmentAmount && (
                <p className="validation-error">
                  Investment Amount is required
                </p>
              )}
              <div style={{ display: "grid", gridAutoFlow: "column" }}>
                <div>
                  <input
                    type="number"
                    value={investmentAmount}
                    onChange={(e) => setInvestmentAmount(e.target.value)}
                    id="invstamount"
                    required
                  />
                </div>

                <div>
                  <select
                    id="invst_type"
                    value={investmentType}
                    onChange={(e) => setInvestmentType(e.target.value)}
                    className="custom-select"
                  >
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="quaterly">Quarterly</option>
                  </select>
                </div>
              </div>

              <label>
                Expected Annual Returns (%) <em className="sip-alert">*</em>
              </label>
              {errors.annualRate && (
                <p className="validation-error">
                  Expected Annual Returns is required
                </p>
              )}
              <input
                type="number"
                value={annualRate}
                onChange={(e) => setAnnualRate(e.target.value)}
                id="expreturn"
                required
              />
            </div>
            <div className="col">
              <label className="form-field-label" id="field3">
                Investment Period (Years) : <em className="sip-alert">*</em>
              </label>
              {errors.investmentPeriod && (
                <p id="val_per" className="validation-error">
                  Investment Period is required
                </p>
              )}
              <input
                type="number"
                value={investmentPeriod}
                onChange={(e) => setInvestmentPeriod(e.target.value)}
                id="invstperiod"
                required
              />

              <label>
                Adjust for Inflation ? <em className="sip-alert">*</em>
              </label>
              <select
                id="inflation"
                value={adjustInflation}
                onChange={(e) => setAdjustInflation(e.target.value)}
                className="custom-select"
              >
                <option value="no">No Inflation</option>
                <option value="6">6%</option>
                <option value="8">8%</option>
                <option value="10">10%</option>
                <option value="12">12%</option>
              </select>
            </div>
          </div>
          <div style={{ textAlign: "center", marginTop: "1.5rem" }}>
            <button type="button" onClick={handleSubmit} id="calculatebtn">
              Calculate
            </button>
          </div>
        </div>
        <div id="result-box" className="result-container">
          <div className="row">
            <div className="col">
              <div id="result1" className="res-box">
                <label>
                  Expected Amount: <em className="sip-alert">*</em>
                </label>
                <span id="rf1">Rs. {result.expectedAmount || 0}</span>
                <br />
                <label>
                  Amount Invested: <em className="sip-alert">*</em>
                </label>
                <span id="rf2">Rs. {result.amountInvested}</span>
                <br />
                <label>
                  Profit Earned: <em className="sip-alert">*</em>
                </label>
                <span id="rf3">Rs. {result.profitEarned || 0}</span>
              </div>
              <div id="result2" className="res-box">
                <Chart result={result} />
              </div>
            </div>

            <div className="col res_table">
              <div className="container res-box" id="result3">
                <table className="table table-hover">
                  <caption id="tab_cap">
                    Projected SIP returns [ @rate ]
                  </caption>
                  <thead>
                    <tr>
                      <th scope="col">Duration</th>
                      <th scope="col">SIP Amount (Rs)</th>
                      <th scope="col">Future Value (Rs)</th>
                    </tr>
                  </thead>
                  <tbody id="projectTable" />
                </table>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "2.5rem" }}>
          <strong>Disclaimer :</strong>{" "}
          {`I DO NOT offer any financial advice here.\n It should be used only for informational purpose.
Investment in mutual funds or any asset class comes with an inherent risk. It's just a web based tool for getting a rough estimate about the future value on your SIP investments. The calculations are based on projected annual returns. The actual annual returns may be higher or lower than the estimated value. And it may have significant impact on the final returns. So, do your own analysis or hire a financial advisor/planner before making any decision.`}
        </div>
      </div>
    </Layout>
  );
};

export default SipCalculator;
