const calculateSIP = (
  investmentAmount,
  investmentType,
  investmentPeriod,
  annualRate,
  adjustInflation
) => {
  let SIP = 0;
  const result = {
    expectedAmount: 0,
    amountInvested: 0,
    profitEarned: 0,
  };

  if (adjustInflation === "no") {
    if (investmentType === "monthly") {
      SIP =
        (investmentAmount *
          ((1 + annualRate / 100 / 12) ** (investmentPeriod * 12) - 1) *
          (1 + annualRate / 100 / 12)) /
        (annualRate / 100 / 12);
      result.expectedAmount = Math.round(SIP);
      result.amountInvested = Math.round(
        investmentAmount * investmentPeriod * 12
      );
      result.profitEarned = Math.round(
        SIP - investmentAmount * investmentPeriod * 12
      );
    }
    if (investmentType === "weekly") {
      SIP =
        (investmentAmount *
          4 *
          ((1 + annualRate / 100 / 12) ** (investmentPeriod * 12) - 1) *
          (1 + annualRate / 100 / 12)) /
        (annualRate / 100 / 12);
      result.expectedAmount = Math.round(SIP);
      result.amountInvested = Math.round(
        investmentAmount * 4 * (investmentPeriod * 12)
      );
      result.profitEarned = Math.round(
        SIP - investmentAmount * 4 * (investmentPeriod * 12)
      );
    }
    if (investmentType === "quaterly") {
      SIP =
        ((investmentAmount / 4) *
          ((1 + annualRate / 100 / 12) ** (investmentPeriod * 12) - 1) *
          (1 + annualRate / 100 / 12)) /
        (annualRate / 100 / 12);
      result.expectedAmount = Math.round(SIP);
      result.amountInvested = Math.round(
        (investmentAmount / 4) * (investmentPeriod * 12)
      );
      result.profitEarned = Math.round(
        SIP - (investmentAmount / 4) * (investmentPeriod * 12)
      );
    }
  }
  if (adjustInflation !== "no") {
    if (investmentType === "monthly") {
      SIP =
        (investmentAmount *
          ((1 + (annualRate - adjustInflation) / 100 / 12) **
            (investmentPeriod * 12) -
            1) *
          (1 + (annualRate - adjustInflation) / 100 / 12)) /
        ((annualRate - adjustInflation) / 100 / 12);
      result.expectedAmount = Math.round(SIP);
      result.amountInvested = Math.round(
        investmentAmount * investmentPeriod * 12
      );
      result.profitEarned = Math.round(
        SIP - investmentAmount * investmentPeriod * 12
      );
    }
    if (investmentType === "weekly") {
      SIP =
        (investmentAmount *
          4 *
          ((1 + (annualRate - adjustInflation) / 100 / 12) **
            (investmentPeriod * 12) -
            1) *
          (1 + (annualRate - adjustInflation) / 100 / 12)) /
        ((annualRate - adjustInflation) / 100 / 12);
      result.expectedAmount = Math.round(SIP);
      result.amountInvested = Math.round(
        investmentAmount * investmentPeriod * 12 * 4
      );
      result.profitEarned = Math.round(
        SIP - investmentAmount * investmentPeriod * 12 * 4
      );
    }
    if (investmentType === "quaterly") {
      SIP =
        ((investmentAmount / 4) *
          ((1 + (annualRate - adjustInflation) / 100 / 12) **
            (investmentPeriod * 12) -
            1) *
          (1 + (annualRate - adjustInflation) / 100 / 12)) /
        ((annualRate - adjustInflation) / 100 / 12);
      result.expectedAmount = Math.round(SIP);
      result.amountInvested = Math.round(
        (investmentAmount / 4) * (investmentPeriod * 12)
      );
      result.profitEarned = Math.round(
        SIP - (investmentAmount / 4) * (investmentPeriod * 12)
      );
    }
  }
  return result;
};

function calculateProjectedData(
  investmentAmount,
  investmentType,
  currentYear,
  annualRate,
  adjustInflation,
) {
  let SIP = 0;
  const projResult = {
    expectedAmount: 0,
    amountInvested: 0,
    profitEarned: 0,
  };

  if (adjustInflation === "no") {
    if (investmentType === "monthly") {
      SIP =
        (investmentAmount *
          ((1 + annualRate / 100 / 12) ** (currentYear * 12) - 1) *
          (1 + annualRate / 100 / 12)) /
        (annualRate / 100 / 12);
      projResult.expectedAmount = Math.round(SIP);
      projResult.amountInvested = Math.round(
        investmentAmount * currentYear * 12
      );
      projResult.profitEarned = Math.round(
        SIP - investmentAmount * currentYear * 12
      );
    }
    if (investmentType === "weekly") {
      SIP =
        (investmentAmount *
          4 *
          ((1 + annualRate / 100 / 12) ** (currentYear * 12) - 1) *
          (1 + annualRate / 100 / 12)) /
        (annualRate / 100 / 12);
      projResult.expectedAmount = Math.round(SIP);
      projResult.amountInvested = Math.round(
        investmentAmount * 4 * (currentYear * 12)
      );
      projResult.profitEarned = Math.round(
        SIP - investmentAmount * 4 * (currentYear * 12)
      );
    }
    if (investmentType === "quaterly") {
      SIP =
        ((investmentAmount / 4) *
          ((1 + annualRate / 100 / 12) ** (currentYear * 12) - 1) *
          (1 + annualRate / 100 / 12)) /
        (annualRate / 100 / 12);
      projResult.expectedAmount = Math.round(SIP);
      projResult.amountInvested = Math.round(
        (investmentAmount / 4) * (currentYear * 12)
      );
      projResult.profitEarned = Math.round(
        SIP - (investmentAmount / 4) * (currentYear * 12)
      );
    }
  }
  if (adjustInflation !== "no") {
    if (investmentType === "monthly") {
      SIP =
        (investmentAmount *
          ((1 + (annualRate - adjustInflation) / 100 / 12) **
            (currentYear * 12) -
            1) *
          (1 + (annualRate - adjustInflation) / 100 / 12)) /
        ((annualRate - adjustInflation) / 100 / 12);
      projResult.expectedAmount = Math.round(SIP);
      projResult.amountInvested = Math.round(
        investmentAmount * currentYear * 12
      );
      projResult.profitEarned = Math.round(
        SIP - investmentAmount * currentYear * 12
      );
    }
    if (investmentType === "weekly") {
      SIP =
        (investmentAmount *
          4 *
          ((1 + (annualRate - adjustInflation) / 100 / 12) **
            (currentYear * 12) -
            1) *
          (1 + (annualRate - adjustInflation) / 100 / 12)) /
        ((annualRate - adjustInflation) / 100 / 12);
      projResult.expectedAmount = Math.round(SIP);
      projResult.amountInvested = Math.round(
        investmentAmount * currentYear * 12 * 4
      );
      projResult.profitEarned = Math.round(
        SIP - investmentAmount * currentYear * 12 * 4
      );
    }
    if (investmentType === "quaterly") {
      SIP =
        ((investmentAmount / 4) *
          ((1 + (annualRate - adjustInflation) / 100 / 12) **
            (currentYear * 12) -
            1) *
          (1 + (annualRate - adjustInflation) / 100 / 12)) /
        ((annualRate - adjustInflation) / 100 / 12);
      projResult.expectedAmount = Math.round(SIP);
      projResult.amountInvested = Math.round(
        (investmentAmount / 4) * (currentYear * 12)
      );
      projResult.profitEarned = Math.round(
        SIP - (investmentAmount / 4) * (currentYear * 12)
      );
    }
  }
  return projResult;
}

export { calculateSIP, calculateProjectedData };
